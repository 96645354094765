<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
           <el-button type="warning">新增</el-button>
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [{name: "运维管理", path: ""}, {name: "车辆信息维护", path: ""}],
        table: {
          api: vm.$api.OrderList,
          query: {
            type: 3,
            begin: '',
            end: '',
            carBlong: null,
            queryContent: null,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [
            {
              type: 'input',
              model: 'queryContent',
              placeholder: '车牌号码'
            }],
          columns: [
            //   {
            //   title: '序号',
            //   width: '50px',
            //   $index: 'index',
            // },
            {
              title: '车牌号码',
              // width: '90px',
              key: 'partnerName',
            }, {
              title: '车辆归属',
              // width: '80px',
              key: 'partnerAccountName',
            }, {
              title: '车辆类型',
              key: 'transportTime',
            }, {
              title: '责任人',
              // showTooltip: true,
              key: 'transportTime',
            }, {
              title: '手机号码',
              // width: '90px',
              key: 'receiveAddressDetail',
            }, {
              title: '年检有效期',
              key: 'transportTime',
              filter: 'str2ymd',
              width: '100px',
            }, {
              title: '所属公司',
              width: '80px',
              // showTooltip: true,
              key: 'orderType',
            }, {
              title: '操作',
              width: '155px',
              render (h, ctx) {
                return h('span', [
                //   h('span', {
                //     class: {
                //       'table-view': true,
                //     },
                //     on: {
                //       // click: vm.goDetail.bind(this, ctx.row)
                //     }
                //   }, '审核'),
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      // click: vm.goEdit.bind(this, ctx.row)
                    }
                  }, '编辑'),
                  h('span', {
                    class: {
                      'table-delete': true
                    },
                    on: {
                      // click: vm.delete.bind(this, ctx.row)
                    }
                  }, '删除')
                ])
              }
            }]
        }
      }
    },
    async mounted () {
      await this.$search(this.table)
    }
  }
</script>

<style lang='sass' scoped>

</style>
